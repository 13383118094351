import React, { ReactNode } from "react";

import "./style/smallView.css";

interface SmallViewProps {
    children: ReactNode
}

function SmallView(props: SmallViewProps) {
    return <div className="small-view">
        {props.children}
    </div>

}

export default SmallView;