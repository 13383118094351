import React, { createRef, ReactNode, useEffect, useState } from "react";

import "./action.css";

function ActionWrapper() {

    const ref = createRef<HTMLDivElement>();
    const [popUp, setPopUp] = useState<ReactNode>();

    useEffect(() => {
        window.addEventListener("openAction", handleOpenPopUp);
        window.addEventListener("click", handleClick);
        return () => {
            window.removeEventListener("openAction", handleOpenPopUp);
            window.removeEventListener("click", handleClick);
        }
    })

    function handleClick(e: Event) {
        if(e.target == ref.current) closeAction();
    }

    const handleOpenPopUp = ((event: CustomEvent) => {
        setPopUp(event.detail.el);
    }) as EventListener;

    if(popUp == undefined) {
        return <></>;
    }

    return <div ref={ref} className="fullscreen-popup-wrapper">
        {popUp}
    </div>
}

function openAction(el: ReactNode) {
    const loaderEvent = new CustomEvent("openAction", {
        detail: {
            el: el
        },
        bubbles: true,
        cancelable: true,
        composed: false,
      });
    window.dispatchEvent(loaderEvent);
}

function closeAction() {
    openAction(undefined);
}

export {openAction, closeAction}
export default ActionWrapper;