import Cookies from "universal-cookie";
import { sendNoAuthRequest, sendRequest, sendRequestWithError } from "./apiManager";

var sessionId: string;

const cookies = new Cookies();

function login(username: string, password: string, stayLoggedIn: boolean, onSuccess: Function, onFail: Function) {
    sendRequestWithError("auth", "POST", {
        "username": username,
        "password": password
    }, (r: any) => {
        if(r.status == 200) {
            sessionId = r.data.sessionId;
            if(stayLoggedIn) {
                storeToCookie();
            }
            onSuccess();
        }else {
            onFail(r.message);
        }
    }, () => {
        onFail("Timeout error. Api is probably offline");
    }, "");
}

function checkSessionId(onSuccess: Function, onFail: Function) {
    loadFromCookie();
    sendRequest("auth", "GET", {}, (r: any) => {
        if(r.status == 200) {
            onSuccess();
        }else {
            onFail();
        }
    }, getSessionId());
}

function loadFromCookie() {
    sessionId = cookies.get("session");
}

function storeToCookie() {
    cookies.set('session', sessionId, { path: '/', expires: new Date(Date.now()+2592000) });
}

function logout(onFinish: Function) {
    sendRequest("auth", "DELETE", {}, () => {
        cookies.remove("session");
        onFinish();

    }, getSessionId());
}

function getSessionId() {
    return sessionId;
}

export {login, getSessionId, checkSessionId, logout}