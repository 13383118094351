import React, {createRef, useEffect, useRef, useState} from "react";

import DisabledEyeIcon from "../assets/icons/disabledEye";
import EyeIcon from "../assets/icons/eye";
import Checkbox from "./checkbox";

import "./style/textField.css";
import { generatePassword } from "../utils/generalUtils";

interface TextFieldProps {
    onChange: Function,
    onEnter?: Function
    defaultValue?: string,
    placeholder: string,
    time?: boolean,
    date?: boolean,
    password?: boolean,
    recieveGenerate?: boolean,
    generateButton?: boolean,
    labelCheckbox?: boolean,
    label?: string,
    labelCheckboxOnChange?: Function,
    textarea?: boolean,
    autogrow?: boolean,
    className?: string,
    second?: boolean,

}

export default function TextField(props: TextFieldProps) {

    const [visible, setVisible] = useState(false);
    const inputEl = createRef<HTMLInputElement>();

    useEffect(() => {

        window.addEventListener("textFieldGenerate", ((e: CustomEvent) => {
            if(props.recieveGenerate || props.generateButton) {
                if(!inputEl.current) return;
                inputEl.current.value = e.detail.text;
                props.onChange(e.detail.text);
            }
        }) as EventListener);
    });

    const inputProps = {
        ref: inputEl,
        defaultValue: props.defaultValue,
        placeholder: props.placeholder ? props.placeholder : "",
        onChange: (e: Event) => {
            if(e.target == null) return;
            var target = e.target as (HTMLInputElement | HTMLTextAreaElement);
            props.onChange(target.value);
            if(props.autogrow) {
                if(target.parentNode == null) return;
                (target.parentNode as HTMLDivElement).dataset.replicatedValue = target.value + "\n\n";
            }
        },
        type: props.time ? "time" : props.date ? "date" : props.password && !visible ? "password" : "text"
    }

    const mainEl = React.cloneElement(props.textarea ? <textarea/> : <input/>, inputProps);

    return <div className={"text-field" + (props.second ? " second" : "") + (props.autogrow ? " autogrow" : "") + (props.className ? " " + props.className : "")} onKeyPress={(e) => {
        if(e.key == "Enter") {
            if(props.onEnter) {
                props.onEnter();
            }
        }
    }}>
        {props.labelCheckbox ? <Checkbox onChange={props.labelCheckboxOnChange == undefined ? () => {} : props.labelCheckboxOnChange}/> : <></>}{props.label != undefined ? <p>{props.label}{props.generateButton ? <a className="generate unselectable" onClick={() => {
            const event = new CustomEvent("textFieldGenerate", {
            detail: {
                text: generatePassword()
            },
            bubbles: true,
            cancelable: true,
            composed: false,
            });
            window.dispatchEvent(event);
        }}>zufällig generieren</a> : <></>}</p> : <></>}
        {mainEl}
        {props.password ? <div className="icon" onClick={() => {
            setVisible(!visible);
        }}>{visible ? <DisabledEyeIcon/> : <EyeIcon/>}</div> : <></>}
    </div>
}