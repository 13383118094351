import React, { useEffect, useState } from "react";
import DashboardPage from "../framework/pages/dashboard";

import bkg from "../assets/img/background.webp";
import SearchCreateButtonHeader from "../framework/components/headers/searchCreateButtonHeader";
import { useNavigate } from "react-router-dom";
import ListView from "../framework/components/views/listView";
import ListElement from "../framework/components/listElement";
import { setLoader } from "../framework/components/loader";
import { loadPerformances } from "../content/performanceManager";
import { deleteQuote, getQuotes, loadQuotes, Quote } from "../content/quoteManager";
import { getFirstFiveWords } from "../framework/utils/formatUtils";
import { closeAction, openAction } from "../framework/actions/actionWrapper";
import OptionAction, { OptionActionOption } from "../framework/actions/optionAction";

export default function Quotes() {

    const navigate = useNavigate();

    const [quotes, setQuotes] = useState<Quote[]>([]);
    const [search, setSearch] = useState<string>();

    function loadData() {
        setLoader(true);
        loadQuotes((q: any) => {
            setTimeout(() => {
                setQuotes(q);
                setLoader(false);
            }, 200);
        });
    }

    return <DashboardPage onLoad={loadData} img={bkg}>
        <h1>Home - Quotes</h1>
        <SearchCreateButtonHeader button={"Create"} onClick={() => {
            navigate("/quotes/add");
        }} onSearch={setSearch}/>
        <ListView>
            {quotes.map(e => {
                if(search != undefined && !e.search(search)) return;
                return <ListElement onDelete={() => {
                    openAction(<OptionAction text="Are you sure you want to delete this performance?" secondOption={new OptionActionOption("delete", () => {
                        setLoader(true);
                        deleteQuote(e.id, () => {
                            closeAction();
                            loadData();
                        });
                    })} firstOption={new OptionActionOption("cancel", () => {
                        closeAction();
                    })} />);
                }} onCopy={() => {
                    navigate("/quotes/copy/" + e.id);
                }} onEdit={() => {
                    navigate("/quotes/edit/" + e.id);
                }} title={getFirstFiveWords(e.quote + "")} date={e.publisher + ""} subtitle={e.location + ""} dateSubtitle={true}/>
            })}
        </ListView>
    </DashboardPage>
}

function setPerformances(p: any) {
    throw new Error("Function not implemented.");
}
