import React from "react";
import Button from "../components/button";
import Action from "./actionPage";

class OptionActionOption {
    name: string;
    onClick: Function;

    constructor(name: string, onClick: Function) {
        this.name = name;
        this.onClick = onClick;
    }
}

interface OptionActionProps {
    text: string,
    firstOption: OptionActionOption,
    secondOption: OptionActionOption
}

function OptionAction(props: OptionActionProps) {
    return <Action className="option">
        <p>{props.text}</p>
        <div className="buttons">
            <Button onClick={props.firstOption.onClick}>{props.firstOption.name}</Button>
            <Button onClick={props.secondOption.onClick}>{props.secondOption.name}</Button>
        </div>
    </Action>;
}

export {OptionActionOption}
export default OptionAction;