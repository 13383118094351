import React, { CSSProperties } from "react";

import "./style/button.css";
import { openPopUp } from "./popUp";

interface ButtonProps {
    disabled?: boolean,
    onClick: Function,
    style?: CSSProperties,
    className?: string,
    medium?: boolean,
    children: React.ReactNode,
    thin?: boolean,
    invert?: boolean,
    wide?: boolean,
    disabledMessage?: string
}

export default function Button(props: ButtonProps) {
    return <button onClick={props.disabled ? () => {
        if(!props.disabledMessage) return;
        openPopUp(props.disabledMessage);
    } : () => {props.onClick();}} style={props.style ? props.style : {}} className={"button" + (props.className ? " " + props.className : "") + (props.medium ? " medium" : "") + (props.disabled ? " disabled" : "") + (props.invert ? " invert" : "") + (React.Children.count(props.children) > 1 ? " multipleChildren" : "") + (props.thin ? " thin" : "") + (props.wide ? " wide" : "")}>{props.children}</button>
}