import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addQuote, Quote } from "../../content/quoteManager";
import { setLoader } from "../../framework/components/loader";
import SingleQuote from "./singleQuote";

function AddQuote() {
    
    const navigate = useNavigate();

    const [quote, setQuote] = useState<Quote>(new Quote());

    return <SingleQuote continueTitle="add" onContinue={() => {
        if(quote == undefined) return;
        addQuote(quote, () => {
            navigate("/quotes");
        })
    }} title="Add Quote" onChange={(key: string, value: string) => {
        var json = JSON.parse(JSON.stringify(quote));
        json[key] = value;
        setQuote(Object.assign(new Quote(), json));
    }}/>;

}

export default AddQuote;