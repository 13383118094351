import React, { createRef, useEffect, useState } from "react";

import {useNavigate} from "react-router-dom";

import "./style/listElement.css";
import PenIcon from "../assets/icons/pen";
import DeleteIcon from "../assets/icons/delete";
import CopyIcon from "../assets/icons/copy";

interface ListElementProps {
    date: string,
    title: string,
    subtitle: string,
    onEdit?: Function,
    onDelete?: Function,
    onCopy?: Function,
    img?: any,
    dateSubtitle?: boolean,
    nohover?: boolean,
    onClick?: Function
}

export default function ListElement(props: ListElementProps) {

    const [open, setOpen] = useState(false);

    const listEl = createRef<HTMLDivElement>();
    const [responsive, setResponsive] = useState<number>(0);
    useEffect(() => {
        checkResponsive();
        window.addEventListener("resize", checkResponsive);
        return () => {
            window.removeEventListener("resize", checkResponsive);
        }
    })

    function checkResponsive() {
        const width = listEl.current?.offsetWidth;
        if(width == undefined) return;
        if(width < 500) {
            setResponsive(2);

        }else if(width < 700) {
            setResponsive(1);
        }else {
            setResponsive(0);
        }
    }

    return <div ref={listEl} onClick={() => {
        if(props.nohover && props.onClick) {
            props.onClick();
        }
        if(responsive >= 1) {
            setOpen(!open);
        }
    }} className={"list-element " + (open ? " open " : "") + (responsive >= 2 ? " mobile " : "") + (responsive >= 1 ? " tablet " : "") +  (props.dateSubtitle ? " date-subtitle" : "") + (props.nohover ? " nohover" : "")}>
        <div className="info">
            
            {props.img ? <div className="img" style={{
                "backgroundImage": "url(" + props.img +  ")"
            }}/> : <></>}
            <p className="date">{props.date}{!props.dateSubtitle ? <></> : <p>{props.subtitle}</p>}</p>
            <p className="title">{responsive >= 1 ? <p className="date">{props.date}</p> : <></>}{props.title}{props.dateSubtitle ? <></> : <p>{props.subtitle}</p>}</p>
            <div className="action-buttons">
                <div className="action" onClick={(e) => {
                    if(!props.onEdit) return;
                    props.onEdit(e);
                }}>
                    <PenIcon/>
                    <a>edit</a>
                </div>
                <hr/>
                <div className="action" onClick={(e) => {
                    if(!props.onDelete) return;
                    props.onDelete(e);
                }}>
                    <DeleteIcon/>
                    <a>delete</a>
                </div>
                <hr/>
                <div className="action" onClick={(e) => {
                    if(!props.onCopy) return;
                    props.onCopy(e);
                }}>
                    <CopyIcon/>
                    <a>copy</a>
                </div>
            </div>
        </div>
    </div>

}