import React from "react";

import "./style/simpleBox.css";

interface SimpleBoxProps {
    title: string;
    onClick: Function
}

function SimpleBox(props: SimpleBoxProps) {
    return <div className="simple-box" onClick={(e) => {
        props.onClick(e);
    }}>
        <h3>{props.title}</h3>
    </div>;
}

export default SimpleBox; 