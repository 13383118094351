import React, { ReactNode } from "react";
import Button from "../components/button";
import SubPageHeader from "../components/headers/subpageHeader";
import DashboardPage from "./dashboard";

interface DashboardSubPageSaveButtonProps {
    img: any,
    backLink: string,
    children: ReactNode,
    title: string,
    onLoad?: Function,
    onSave: Function,
    saveButtonTitle?: string,
    disabled?: boolean,
    disabledMessage?: string

}

function DashboardSubPageSaveButton(props: DashboardSubPageSaveButtonProps) {
    return <DashboardPage onLoad={props.onLoad} className="sub" img={props.img}>
        <SubPageHeader back={props.backLink} title={props.title}/>
        <div className="content">
            {props.children}
        </div>
        <Button disabledMessage={props.disabledMessage} disabled={props.disabled} wide onClick={props.onSave}>{props.saveButtonTitle ? props.saveButtonTitle : "Save changes"}</Button>
    </DashboardPage>
}

export default DashboardSubPageSaveButton;