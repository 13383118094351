import React, { useEffect, useState } from "react";

import "./style/popUp.css";

const time = 4000;

function PopUp() {

    const [open, setOpen] = useState(false);
    const [txt, setTxt] = useState("");

    useEffect(() => {
        window.addEventListener("openPopup", ((event: CustomEvent) => {
            setTxt(event.detail.txt);
            if(open) return;
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, time);
          }) as EventListener);
    });

    return <div className={"popup" + (open ? " open" : "")}>
        <h1>{txt}</h1>
    </div>;
}

function openPopUp(txt: string) {
    const e = new CustomEvent("openPopup", {
        detail: {
            "txt": txt
        }
      });
    window.dispatchEvent(e);
}

export {openPopUp}
export default PopUp;