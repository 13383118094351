import { openPopUp } from "../framework/components/popUp";
import { sendNoAuthRequest, sendRequest } from "./apiManager";
import { uploadImage } from "./imageManager";
import { getSessionId } from "./loginManager";

function loadImageGallery(onFinish: Function) {
    sendNoAuthRequest("gallery", "GET", {}, (r: any) => {
        if(r.status == 200) {
            const output = [];
            for(var i = 0; i < r.data.images.length; i++) {
                output.push(r.data.images[i].image);
            }
            onFinish(output);
        }else {
            openPopUp(r.message);
        }
    });
}

var alreadyAdding = false;
function addImageToGallery(file:File, onFinish: Function) {
    if(alreadyAdding) return;
    alreadyAdding = true;
    uploadImage(file, (v: string) => {
        sendRequest("gallery", "POST", {
            "image": v
        }, (r: any) => {
            if(r.status == 200) {
                onFinish();
                alreadyAdding = false;
            }else {
                openPopUp(r.message);
            }

        }, getSessionId());
    })
}

function removeImageFromGallery(image: string, onFinish: Function) {
    sendRequest("gallery/" + image, "DELETE", {}, (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }

    }, getSessionId());
}

function updateImage(image: string, file: File, onFinish: Function) {
    removeImageFromGallery(image, () => {
        addImageToGallery(file, onFinish);
    })
}

export {loadImageGallery, addImageToGallery, removeImageFromGallery, updateImage}