import React, { useEffect, useState } from "react";

import DashboardPage from "../framework/pages/dashboard";
import bkg from "../assets/img/background.webp";
import SearchButtonHeader from "../framework/components/headers/searchButtonHeader";
import AddIcon from "../framework/assets/icons/add";
import SearchCreateButtonHeader from "../framework/components/headers/searchCreateButtonHeader";
import ListView from "../framework/components/views/listView";
import ListElement from "../framework/components/listElement";
import { deletePerformance, getPerformances, loadPerformances, loadUpcomingPerformances, Performance } from "../content/performanceManager";
import { setLoader } from "../framework/components/loader";
import { formatDate } from "../framework/utils/formatUtils";
import { useNavigate } from "react-router-dom";
import { getImageLink } from "../content/imageManager";
import { closeAction, openAction } from "../framework/actions/actionWrapper";
import OptionAction, { OptionActionOption } from "../framework/actions/optionAction";
import SearchCreateButtonHeaderCheckbox from "../framework/components/headers/searchCreateButtonHeaderCheckbox";

export default function Performances() {

    const navigate = useNavigate();

    const [showPast, setShowPast] = useState<boolean>(false);
    const [prevShowPastValue, setPrevShowPastValue] = useState<boolean>(false);
    const [performances, setPerformances] = useState<Performance[]>([]);
    const [search, setSearch] = useState<string>();

    function loadData() {
        setLoader(true);

        var loadFunction = loadPerformances;
        if(!showPast) {
            loadFunction = loadUpcomingPerformances;
        }

        loadFunction((p: any) => {
            if(showPast) {
                p = p.reverse();
            }
            setPerformances(p);
            setTimeout(() => {
                setLoader(false);
            }, 200);
        });
    }

    useEffect(() => {
        console.log(showPast, prevShowPastValue);
        if(showPast != prevShowPastValue) {
            setPrevShowPastValue(showPast);
            loadData();
        }
    })

    return <DashboardPage onLoad={loadData} img={bkg}>
        <h1>Performances</h1>
        <SearchCreateButtonHeaderCheckbox checkboxLabel="show past" onCheckbox={setShowPast} button={"Create"} onClick={() => {
            navigate("/performances/add");
        }} onSearch={setSearch}/>
        <ListView>
            {performances.map(e => {
                if(search != undefined && !e.search(search)) return;
                return <ListElement onDelete={() => {
                    openAction(<OptionAction text="Are you sure you want to delete this performance?" secondOption={new OptionActionOption("delete", () => {
                        setLoader(true);
                        deletePerformance(e.id, () => {
                            closeAction();
                            loadData();
                        });
                    })} firstOption={new OptionActionOption("cancel", () => {
                        closeAction();
                    })} />);
                }} onCopy={() => {
                    navigate("/performances/copy/" + e.id);
                }} onEdit={() => {
                    navigate("/performances/edit/" + e.id);
                }} date={formatDate(e.date == null ? new Date() : e.date)} title={e.category + " " + e.location} subtitle={e.feature == null ? e.composer == null ? "" : e.composer : e.feature} img={e.image == undefined ? undefined : getImageLink(e.image)}/>;
            })}
        </ListView>
    </DashboardPage>
}