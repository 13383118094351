import { openPopUp } from "../framework/components/popUp";
import { sendNoAuthRequest, sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

class Biography {
    id: number = -1;
    title?: string;
    text?: string;
    image?: string;
}

function loadBiography(id: number, onFinish: Function) {
    sendNoAuthRequest("biography/" + id, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(Object.assign(new Biography(), r.data.biography));
        }else {
            openPopUp(r.message);
        }
    })
}

function loadBiographySections(onFinish: Function) {
    sendNoAuthRequest("biography", "GET", {}, (r: any) => {
        if(r.status == 200) {
            const sections = [];
            for(var i = 0; i < r.data.sections.length; i++) {
                const current = Object.assign(new Biography(), r.data.sections[i]);
                sections.push(current);
            }
            onFinish(sections);
        }else {
            openPopUp(r.message);
        }
    })
}

function updateBiography(biography: Biography, onFinish: Function) {
    sendRequest("biography/" + biography.id, "POST", JSON.parse(JSON.stringify(biography)), (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {loadBiography, loadBiographySections, updateBiography, Biography}