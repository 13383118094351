import React, { createRef, ReactNode, useEffect, useRef, useState } from "react";

import "./style/gridView.css";

interface GridViewProps {
    label?: string,
    elementCount: number,
    children: ReactNode,
    breakPoints?: number[]
}

function GridView(props: GridViewProps) {

    const childrenEl = createRef<HTMLDivElement>();
    const [templateColumns, setTemplateColumns] = useState("");
    const [elementSize, setElementSize] = useState<string>();

    
    useEffect(() => {
        if(elementSize == undefined) {
            updateView();
        }
        window.addEventListener("resize", updateView);
        return () => {
            window.removeEventListener("resize", updateView);
        }

    })

    function updateView() {
        var elementCount = props.elementCount;
        if(props.breakPoints) {
            var amount = 0;
            for(var i = 0; i < props.breakPoints.length; i++) {
                if(window.innerWidth < props.breakPoints[i]) {
                    amount += 1;
                }
            }
            elementCount -= amount;
        }
        var size = (childrenEl.current == undefined ? "" : "calc((" + childrenEl.current.offsetWidth + "px - " + (elementCount-1) + "*var(--gap))/" + elementCount + ") ");
        setElementSize(size);
        var temp = "";
        for(var i = 0; i < elementCount; i++) {
            temp += "var(--size) ";
        }
        setTemplateColumns(temp);
    }

    return <div className="grid-view text-field">
        {props.label ? <p>{props.label}</p> : <></>}
        <div ref={childrenEl} className="children" style={{
            "gridTemplateColumns": templateColumns,
            "--size": elementSize
        } as React.CSSProperties}>
            {props.children}
        </div>
    </div>;
}

export default GridView;