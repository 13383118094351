import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addPerformance, loadOnePerformance, Performance, updatePerformance } from "../../content/performanceManager";
import { setLoader } from "../../framework/components/loader";
import SinglePerformance from "./singlePerformance";

function AddPerformance() {

    const navigate = useNavigate();
    
    const [performance, setPerformance] = useState<Performance>(new Performance());

    return <SinglePerformance continueTitle="add" onContinue={() => {
        if(performance == undefined) return;
        addPerformance(performance, () => {
            navigate("/performances");
        });
    }} title="Create Performance" onChange={(key: string, value: any) => {
        if(performance != undefined) {
            var json = JSON.parse(JSON.stringify(performance));
            json[key] = value;
            setPerformance(Object.assign(new Performance(), json));
        }
    }} defaultValue={performance}/>
}

export default AddPerformance;