function formatDate(d: Date) {
    var dateObj = new Date(d);
    return addZeroToNumberUnderTen(dateObj.getDate()) + "." + addZeroToNumberUnderTen(dateObj.getMonth()+1) + "." + dateObj.getFullYear();
}

function addZeroToNumberUnderTen(number: number) {
    if(number < 10) {
        return "0" + number;
    }
    return number;
}

function getFirstFiveWords(text: string) {
    const split = text.split(" ");
    if(split.length <= 5) {
        return text;
    }
    var output = "";
    for(var i = 0; i < 5; i++) {
        output += split[i] + " "
    }
    return output.trim() + "...";
}

export {formatDate, getFirstFiveWords}