import React from "react";
import { getImageLink } from "../../content/imageManager";
import Button from "./button";
import ArrowBigIcon from "../assets/icons/arrowBig";

import "./style/pickItemBox.css";

interface PickItemBoxProps {
    title?: string,
    subtitle?: string,
    image?: string,
    onClick: Function
    empty: boolean
}

function PickItemBox(props: PickItemBoxProps) {

    if(props.empty) {
        return <div onClick={() => {
            props.onClick();
        }} className="pick-item simple-box empty">
            <div className="titles">
                <p>Empty Slot</p>
                <p>Click to select</p>
            </div>
        <div className="arrow">
            <Button thin onClick={props.onClick}><ArrowBigIcon/></Button>
        </div>
        </div>
    }

    return <div onClick={() => {
        props.onClick();
    }} className="pick-item simple-box">
        <div className="img" style={{
            backgroundImage: "url(" + (props.image == undefined ? "" : props.image) + ")"
        }}/>
        <div className="titles">
            <p>{props.subtitle}</p>
            <p>{props.title}</p>
        </div>
        <div className="arrow">
            <Button thin onClick={props.onClick}><ArrowBigIcon/></Button>
        </div>
    </div>

}

export default PickItemBox;