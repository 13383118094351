import React from "react";
import ArrowBigIcon from "../../assets/icons/arrowBig";
import Button from "../button";

interface SubPageFooterProps {
    continueTitle: string,
    onContinue: Function,
    backTitle?: string,
    onBack?: Function
}

export default function SubPageFooter(props: SubPageFooterProps) {
    return <footer>
        {props.backTitle != undefined && props.onBack != undefined ? <Button className="back" onClick={props.onBack} thin><ArrowBigIcon/>{props.backTitle}</Button> : <></>}
        <Button className="next" onClick={props.onContinue} thin>{props.continueTitle}<ArrowBigIcon/></Button>
    </footer>
}