import React, { useState } from "react";

import bkg from "../assets/img/background.webp";
import DashboardPage from "../framework/pages/dashboard";
import ListView from "../framework/components/views/listView";
import GridView from "../framework/components/views/gridView";
import ImagePicker from "../framework/components/imagePicker";
import { addImageToGallery, loadImageGallery, removeImageFromGallery, updateImage } from "../content/galleryManager";
import { getImageLink } from "../content/imageManager";
import { setLoader } from "../framework/components/loader";

function ImageGalleryPage() {

    const [images, setImages] = useState<string[]>();

    function loadData() {
        setLoader(true);
        loadImageGallery((v: any) => {
            setImages(v);
            setLoader(false);
        });
    }

    return <DashboardPage onLoad={loadData} img={bkg}>
        <h1>Image Gallery</h1>
        <GridView elementCount={5} breakPoints={[900, 800, 650, 450]}>
            {images == undefined ? <></> : images.map(e => {
                return <ImagePicker img={getImageLink(e)} onChange={(file: File | undefined) => {
                    if(file == undefined) {
                        removeImageFromGallery(e, loadData);
                    }else {
                        updateImage(e, file, loadData);
                    }
                }}/>;
            })}
            <ImagePicker onChange={(file: File) => {
                setLoader(true);
                addImageToGallery(file, () => {
                    loadData();
                });
            }}/>
        </GridView>
    </DashboardPage>;
}

export default ImageGalleryPage;