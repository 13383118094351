import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loadOnePerformance, Performance, updatePerformance } from "../../content/performanceManager";
import { setLoader } from "../../framework/components/loader";
import SinglePerformance from "./singlePerformance";

function EditPerformance() {

    const navigate = useNavigate();

    const {id} = useParams();
    
    const [performance, setPerformance] = useState<Performance>();


    useEffect(() => {

    })

    return <SinglePerformance continueTitle="update" onContinue={() => {
        if(performance == undefined) return;
        updatePerformance(performance, () => {
            navigate("/performances");
        });
    }} title="Edit Performance" onLoad={() => {
        setLoader(true);
        const idcast = Number(id);
        loadOnePerformance(idcast, (p: Performance) => {
            setPerformance(p);
            setTimeout(() => {
                setLoader(false);
            }, 200);
        })
    }} onChange={(key: string, value: any) => {
        if(performance != undefined) {
            var json = JSON.parse(JSON.stringify(performance));
            json[key] = value;
            setPerformance(Object.assign(new Performance(), json));
        }
    }} defaultValue={performance}/>
}

export default EditPerformance;