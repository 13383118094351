import React from "react";
import SearchCreateButtonHeader from "./searchCreateButtonHeader";
import Checkbox from "../checkbox";

interface SearchButtonHeaderProps {
    onSearch: Function,
    onClick: Function,
    button: string,
    onCheckbox: Function,
    checkboxLabel: string
}

export default function SearchCreateButtonHeaderCheckbox(props: SearchButtonHeaderProps) {
    return <SearchCreateButtonHeader onSearch={props.onSearch} onClick={props.onClick} button={props.button}>
        <Checkbox clickable onChange={props.onCheckbox} label={props.checkboxLabel}/>
    </SearchCreateButtonHeader>;
}