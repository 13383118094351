import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./style/navigation.css";
import { logout } from "../../content/loginManager";
import { setLoader } from "./loader";
import ChevronIcon from "../assets/icons/chevron";

function Navigation() {

    const [mobileOpen, setMobileOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const elements = [
        "Performances",
        "Past Performances",
        "Quotes",
        "Biography",
        "Videos",
        "Gallery",
        "Logout"
    ]

    return <nav className={"navigation" + (mobileOpen ? " open" : "")}>
        <div className="close" onClick={() => {
            setMobileOpen(!mobileOpen);
        }}>
            <ChevronIcon/>
        </div>
        <h1>Andrea Cicalese</h1>
        <h2>Dashboard</h2>
        <ul>
            {elements.map(e => {
                return <li onClick={() => {
                    if(e.toLowerCase() == "logout") {
                        logout(() => {
                            navigate("/login");
                        });
                    }else {

                        navigate("/" + e.toLowerCase().replace(" ", "-"));
                    }
                }} className={location.pathname.startsWith("/" + e.toLowerCase().replace(" ", "-")) ? "active" : ""}>{e}</li>
            })}
        </ul>
    </nav>
}

export default Navigation;