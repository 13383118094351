import { openPopUp } from "../framework/components/popUp";
import { sendNoAuthRequest, sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

function getStatic(key: string, onFinish: Function, onError: Function) {
    sendNoAuthRequest("static/" + key, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(r.data.value);
        }else {
            onError();
        }
    });
}

function updateStatic(key: string, value: string, onFinish: Function) {
    sendRequest("static", "POST", {
        "key": key,
        "value": value
    }, (r:any) => {
        if(r.status == 200) {
            onFinish(r.data);
        } else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {updateStatic, getStatic}