import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


import bkg from "../assets/img/background.webp";
import { Biography, loadBiography, loadBiographySections, updateBiography } from "../content/biographyManager";
import { getImageLink, uploadImage } from "../content/imageManager";
import SectionHeader from "../framework/components/headers/sectionHeader";
import ImagePicker from "../framework/components/imagePicker";
import { setLoader } from "../framework/components/loader";
import SmallView from "../framework/components/views/smallView";
import TextField from "../framework/components/textField";
import DashboardPage from "../framework/pages/dashboard";
import GridView from "../framework/components/views/gridView";
import SimpleBox from "../framework/components/simpleBox";

function BiographyPage() {

    const {section} = useParams();

    const [data, setData] = useState<Biography[]>();

    const currentSectionId = Number(section);
    const [currentSection, setCurrentSection] =useState<Biography>();
    const [rowElements, setRowElements] = useState(window.innerWidth <= 400 ? 1 : window.innerWidth <= 550 ? 2 : window.innerWidth <= 700 ? 3 : window.innerWidth <= 940 ? 4 : 5);
    const navigate = useNavigate();

    function loadData() {
        setLoader(true);
        loadBiographySections((b: Biography[]) => {
            setData(b);
            setCurrentSection(b[currentSectionId]);
            setTimeout(() => {
                setLoader(false);
            }, 200);
        })
    }

    function update (key: string, value: string) {
        const temp = data;
        if(temp == undefined) return;
        var json = JSON.parse(JSON.stringify(currentSection));
        json[key] = value;
        temp[currentSectionId] = Object.assign(new Biography(), json);
        setData(temp);
        setCurrentSection(temp[currentSectionId]);
    }

    function updateImage(file: File, id: number) {
        setLoader(true);
        if(data && data[id]) {
            uploadImage(file, (imageid: string) => {
                const current = data[id];
                current.image = imageid;
                updateBiography(current, () => {
                    loadData();
                });
            });
        }
    }

    useEffect(() => {
        responsiveUpdate();
        window.addEventListener("resize", responsiveUpdate);
        return () => {
            window.removeEventListener("resize", responsiveUpdate);
        }
    })

    function responsiveUpdate() {
        var newValue = 5;
        if(window.innerWidth <= 940) {
            newValue = 4;
        }
        if(window.innerWidth <= 700) {
            newValue = 3;
        }
        if(window.innerWidth <= 550) {
            newValue = 2;
        }
        if(window.innerWidth <= 400) {
            newValue = 1;
        }
        console.log(window.innerWidth, newValue);
        if(rowElements != newValue) {
            setRowElements(newValue);
        }
        return newValue;
    }

    return <DashboardPage onLoad={loadData} img={bkg}>
        <h1>Home - Biography</h1>
        <GridView label="Images" elementCount={rowElements}>
            <ImagePicker img={data != undefined ? getImageLink(data[0].image != undefined ? data[0].image : "") : ""} onChange={(file: File) => {
                updateImage(file, 0);
            }}/>
            <ImagePicker img={data != undefined ? getImageLink(data[2].image != undefined ? data[2].image : "") : ""} onChange={(file: File) => {
                updateImage(file, 2);
            }}/>
            <ImagePicker img={data != undefined ? getImageLink(data[3].image != undefined ? data[3].image : "") : ""} onChange={(file: File) => {
                updateImage(file, 3);
            }}/>
        </GridView>
        <GridView label="Text" elementCount={rowElements == 5 ? 6 : rowElements}>
            <SimpleBox title="Section 1" onClick={() => {
                navigate("/biography/1");
            }}/>
            <SimpleBox title="Section 2" onClick={() => {
                navigate("/biography/2");
                
            }}/>
            <SimpleBox title="Section 3" onClick={() => {
                navigate("/biography/3");
                
            }}/>
            <SimpleBox title="Section 4" onClick={() => {
                navigate("/biography/4");
                
            }}/>
            <SimpleBox title="Section 5" onClick={() => {
                navigate("/biography/5");
                
            }}/>
            <SimpleBox title="Section 6" onClick={() => {
                navigate("/biography/6");
                
            }}/>
        </GridView>
    </DashboardPage>;


}

export default BiographyPage;