import React from "react";
import DeleteIcon from "../assets/icons/delete";
import UploadIcon from "../assets/icons/upload";
import IconButton from "../components/iconButton";
import { openPopUp } from "../components/popUp";
import { isImage } from "../utils/fileUtils";
import Action from "./actionPage";

interface EditImageActionProps {
    onFinish: Function,
    img: string
}

function EditImageAction(props: EditImageActionProps) {
    return <Action className="edit" draganddrop onDrop={(file: File) => {
        if(isImage(file.name)) {
            props.onFinish(file);
        }else {
            openPopUp("Invalid file type");
        }
    }}>
        <div className="image-preview" style={{
            "backgroundImage": "url(" + props.img + ")"
        }}/>
        <IconButton onClick={() => {
            var input = document.createElement('input');
            input.type = 'file';
            input.addEventListener("change", ((e) => {
                var files = (e.target as HTMLInputElement).files;
                if(files == undefined || files.length != 1) {
                    openPopUp("Something went wrong");
                }else {
                    var file = files[0];
                    if(isImage(file.name)) {
                        props.onFinish(file);
                    }else {
                        openPopUp("Invalid file type");
                    }
                }
            }));
            input.click();

        }}>
            <UploadIcon/>
        </IconButton>
        <p>Edit Image</p>
        <IconButton onClick={() => {
            props.onFinish(undefined);

        }}>
            <DeleteIcon/>
        </IconButton>
        <p>Delete Image</p>
    </Action>;
}

export default EditImageAction;