import React from "react";
import { Search } from "react-router-dom";
import TextField from "../textField";

interface SearchHeaderProps {
    onSearch: Function
}

export default function SearchHeader(props: SearchHeaderProps) {
    return <header>
        <TextField placeholder="Suche" onChange={props.onSearch}/>
    </header>;
}