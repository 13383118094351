import React, { ReactNode } from "react";
import DashboardPage from "./dashboard";
import Button from "../components/button";
import ArrowBigIcon from "../assets/icons/arrowBig";
import { useNavigate } from "react-router-dom";
import SubPageHeader from "../components/headers/subpageHeader";

import "./style/dashboardSubPage.css";
import SubPageFooter from "../components/headers/subPageFooter";

interface DashboardSubPageProps {
    img: any,
    backLink: string,
    forwardLink?: string,
    stepBack?: boolean,
    stepForward?: boolean,
    children: ReactNode,
    title: string,
    onLoad?: Function,
    continueTitle: string,
    onContinue: Function
}

function DashboardSubPage(props: DashboardSubPageProps) {

    const navigate = useNavigate();

    return <DashboardPage onLoad={props.onLoad} className="sub" img={props.img}>
        <SubPageHeader back={props.stepBack == true ? undefined : props.backLink} title={props.title}/>
        <div className="content">
            {props.children}
        </div>
        <SubPageFooter continueTitle={props.continueTitle} onContinue={props.onContinue}/>
    </DashboardPage>

}

export default DashboardSubPage;