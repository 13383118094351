import { openPopUp } from "../framework/components/popUp";
import { sendNoAuthRequest, sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

class VideoObject {
    id:number = -1;
    name?: string;
    link?: string;
    description?: string;
}

function loadVideoObject(id: number, onFinish: Function) {
    sendNoAuthRequest("videos/" + id, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(Object.assign(new VideoObject(), r.data.video));
        }else {
            openPopUp(r.message);
        }
    });
}

function updateVideo(video: VideoObject, onFinish: Function) {
    sendRequest("videos/" + video.id, "POST", JSON.parse(JSON.stringify(video)), (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {VideoObject, loadVideoObject, updateVideo}