import React from "react";
import UploadIcon from "../assets/icons/upload";
import IconButton from "../components/iconButton";
import { openPopUp } from "../components/popUp";
import { isImage } from "../utils/fileUtils";
import Action from "./actionPage";

interface AddImageActionProps {
    onFinish: Function
}

function AddImageAction(props: AddImageActionProps) {
    return <Action className="add" draganddrop onDrop={(file: File) => {
        if(isImage(file.name)) {
            props.onFinish(file);
        }else {
            openPopUp("Invalid file type");
        }
    }}>
        <IconButton onClick={() => {
            var input = document.createElement('input');
            input.type = 'file';
            input.addEventListener("change", ((e) => {
                var files = (e.target as HTMLInputElement).files;
                if(files == undefined || files.length != 1) {
                    openPopUp("Something went wrong");
                }else {
                    var file = files[0];
                    if(isImage(file.name)) {
                        props.onFinish(file);
                    }else {
                        openPopUp("Invalid file type");
                    }
                }
            }));
            input.click();

        }}>
            <UploadIcon/>
        </IconButton>
        <p>Select an image by clicking on the button or drag it onto this window.</p>
    </Action>;
}

export default AddImageAction;