import React, {useEffect, createRef, useRef, useState} from "react";

import "./style/checkbox.css";

interface CheckboxProps {
    defaultValue?: boolean,
    clickable?: boolean,
    label?: string,
    onChange: Function
}

export default function Checkbox(props: CheckboxProps) {

    const inputEl = createRef<HTMLInputElement>();
    const firstExecute = useRef(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if(props.defaultValue && !firstExecute.current && inputEl.current != null) {
            inputEl.current.defaultValue = props.defaultValue + "";
            setChecked(true);
            firstExecute.current = true;
        }
    });

    return <div className={"checkbox checkbox-wrapper-46" + (checked ? " checked" : "")}>
            <input ref={inputEl} onClick={(e) => {props.onChange(!checked);
            if(props.clickable == true) {
                setChecked(!checked);
            }else {
                e.preventDefault();
            }}} className="inp-cbx" id="cbx-46" type="checkbox" />
            <label className="cbx" htmlFor="cbx-46"><span>
            <svg width="12px" height="10px">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg></span><span>{props.label}</span>
            </label>
        </div>;

}