function getExtension(filename: string) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }
  
  function isImage(filename: string) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'png':
      case "jpeg":
      case "webp":
        //etc
        return true;
    }
    return false;
  }
  
  function isVideo(filename: string) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
        // etc
        return true;
    }
    return false;
  }

  export {isVideo, isImage}