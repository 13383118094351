import React, { createRef, ReactNode, useEffect, useRef, useState } from "react";
import CloseIcon from "../assets/icons/close";
import UploadIcon from "../assets/icons/upload";
import { openPopUp } from "../components/popUp";
import { closeAction } from "./actionWrapper";

interface ActionProps {
    children: ReactNode,
    className?: string,
    draganddrop?: boolean,
    onDrop?: Function
}

export default function Action(props: ActionProps) {

    const [drag, setDrag] = useState(false);
    const overlayRef = createRef<HTMLDivElement>();
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {

        overlayRef.current!.style.width = ref.current?.offsetWidth + "px";
        overlayRef.current!.style.height = ref.current?.offsetHeight + "px";
        window.addEventListener("keyup", keyListener);
        if(props.draganddrop) {
            if(ref.current == undefined || overlayRef.current == undefined) return;
            ref.current.addEventListener("dragenter", () => {
                setDrag(true);
            })
            overlayRef.current.addEventListener("dragleave", (e) => {
                setDrag(false);
                e.preventDefault();
            });
            overlayRef.current.addEventListener("dragover", (e) => {
                if(!drag) {
                    
                    setDrag(true);
                }
                e.preventDefault();
              })
            overlayRef.current.addEventListener("drop", (e) => {
                
                setDrag(false);
                e.preventDefault();
                if(e.dataTransfer && e.dataTransfer.files.length != 0){
                    var files = e.dataTransfer.files //Array of filenames
                    if(files.length > 1) {
                        openPopUp("Bitte immer nur 1 Datei hochladen");
                        return;
                    }
                    if(props.onDrop) {
                        props.onDrop(files[0]);
                    }
            
                }else{
                    openPopUp("Der Browser unterstützt Drag and drop nicht");
                }
            });
            var children = overlayRef.current.children;
            for (var i = 0; i < children.length; i++) {
                var child = children[i];
                child.addEventListener("dragenter", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                })
                child.addEventListener("dragleave", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                })
            }
        }
        return () => {
            window.removeEventListener("keyup", keyListener);
        }
    })

    function keyListener(e: KeyboardEvent) {
        if (e.key === "Escape") {
            closeAction();
        }

    }

    return <div ref={ref} className={"action" + (props.className ? " " + props.className : "") + (drag ? " drag" : "")}>
        <div className="overlay" ref={overlayRef}>
            <UploadIcon/>
            <p>Drop file to upload image</p>
        </div>
        <div className="close" onClick={closeAction}>
            <CloseIcon/>
        </div>
        {props.children}
    </div>
}