import React, { useState } from "react";

import bkg from "../assets/img/background.webp";
import DashboardPage from "../framework/pages/dashboard";
import GridView from "../framework/components/views/gridView";
import PickItemBox from "../framework/components/pickItemBox";
import { getStatic } from "../content/staticManager";
import { getPerformances, loadOnePerformance, Performance } from "../content/performanceManager";
import { formatDate } from "../framework/utils/formatUtils";
import { useNavigate } from "react-router-dom";
import { getImageLink } from "../content/imageManager";
import { setLoader } from "../framework/components/loader";

function PastPerformancesPage() {

    const navigate = useNavigate();

    const keys_count = 4;

    const [performances, setPerformances] = useState<(Performance | undefined)[]>();

    function loadData(i: number, output: (Performance | undefined)[]) {
        setLoader(true);
        if(i == keys_count) {
            setPerformances(output);
            setTimeout(() => {
                setLoader(false);
            }, 200);
            return;
        }
        getStatic("past_performance_" + i, (v: number) => {
            loadOnePerformance(v, (p: Performance) => {
                var new_output = output;
                new_output.push(p);
                loadData(i+1, new_output);
            })
        }, () => {
            var new_output = output;
            new_output.push(undefined);
            loadData(i+1, new_output);

        })
    }

    return <DashboardPage onLoad={() => {
        loadData(0, []);
    }} img={bkg}>
        <h1>Home - Past Performances</h1>
        <GridView breakPoints={[850]} elementCount={2}>
            {performances?.map((e, i) => {
                if(e == undefined) {
                    return <PickItemBox empty={true} onClick={() => {
                        navigate("/past-performances/select/" + i);
                    }}/>
                }else {
                    return <PickItemBox empty={false} title={e.category + " " + e.location} subtitle={e.date != undefined ? formatDate(e.date) : ""} image={e.image ? getImageLink(e.image) : ""} onClick={() => {
                        navigate("/past-performances/select/" + i);

                    }}/>;
                }
            })}
        </GridView>
    </DashboardPage>

}

export default PastPerformancesPage;