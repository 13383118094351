import React, { ReactNode } from "react";
import TextField from "../textField";
import Button from "../button";
import ArrowBigIcon from "../../assets/icons/arrowBig";
import { useNavigate } from "react-router-dom";

interface SubPageHeaderProps {
    back?: string,
    title: string
}

function SubPageHeader(props: SubPageHeaderProps) {

    const navigate = useNavigate();

    return <header className="subpage">
        {props.back == undefined ? <></> : <Button className="back" medium onClick={() => {
            navigate("/" + props.back);
        }}><ArrowBigIcon/></Button>}
        <h1>{props.title}</h1>
    </header>;
}

export default SubPageHeader;