import React, { ReactNode } from "react";

import "./layouts.css";

interface MultipleTextFieldsLayoutProps {
    children: ReactNode
}

export default function MultipleTextFieldsLayout(props: MultipleTextFieldsLayoutProps) {
    return <div className="layout multiple-text-fields">
        {props.children}
    </div>
}