import TextField from "../textField";
import Button from "../button";
import AddIcon from "../../assets/icons/add";

interface SearchButtonHeaderProps {
    onSearch: Function,
    onClick: Function,
    button: string,
    children?: any
}

function SearchCreateButtonHeader(props: SearchButtonHeaderProps) {
    return <header>
        <TextField placeholder="Suche" onChange={props.onSearch}/>
        {props.children}
        <Button className="create" invert onClick={props.onClick}>{props.button}<AddIcon/></Button>
    </header>;
}

export default SearchCreateButtonHeader;