import { openPopUp } from "../framework/components/popUp";
import { sendNoAuthRequest, sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

class Quote {
    id: number = -1;
    publisher?: string;
    location?: string;
    quote?: string;

    search(text: string) {

        var split = text.split(" ");
        for(var i = 0; i < split.length; i++) {
            const current = split[i].toLowerCase();
            if(this.publisher?.toLowerCase().includes(current) || this.location?.toLowerCase().includes(current) || this.quote?.toLowerCase().includes(current)) return true;
        }
        return false;
    }
}

var quotes: Quote[] = []

function getQuotes() {
    return quotes;
}

function loadOneQuote(id: number, onFinish: Function) {
    sendNoAuthRequest("quote/" + id, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(Object.assign(new Quote(), r.data.quote));
        }else {
            openPopUp(r.message);
        }
    })
}

function loadQuotes(onFinish: Function) {
    sendNoAuthRequest("quote", "GET", {}, (r: any) => {
        if(r.status == 200) {
            quotes = [];
            for(var i = 0; i < r.data.quotes.length; i++) {
                const current = Object.assign(new Quote(), r.data.quotes[i]);
                quotes.push(current);
            }
            onFinish(quotes);
        }else {
            openPopUp(r.message);
        }
    })
}

function checkQuote(quote: Quote) {
    if(quote.location != undefined && quote.location != null && quote.quote != undefined && quote.quote != null && quote.publisher != undefined && quote.publisher != null) {
        return true;
    }else {
        openPopUp("Please fill in all required fields");
        return false;
    }
}

function addQuote(quote: Quote, onFinish: Function) {
    if(!checkQuote(quote)) return;
    sendRequest("quote", "POST", JSON.parse(JSON.stringify(quote)), (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function updateQuote(quote: Quote, onFinish: Function) {
    if(!checkQuote(quote)) return;
    sendRequest("quote/" + quote.id, "POST", JSON.parse(JSON.stringify(quote)), (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function deleteQuote(id: number, onFinish: Function) {
    sendRequest("quote/" + id, "DELETE", {}, (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {Quote, loadQuotes, getQuotes, deleteQuote, addQuote, loadOneQuote, updateQuote}