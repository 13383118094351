import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addPerformance, loadOnePerformance, Performance, updatePerformance } from "../../content/performanceManager";
import { setLoader } from "../../framework/components/loader";
import SinglePerformance from "./singlePerformance";

function CopyPerformance() {

    const navigate = useNavigate();

    const {id} = useParams();
    
    const [performance, setPerformance] = useState<Performance>(new Performance());

    return <SinglePerformance continueTitle="copy" onContinue={() => {
        if(performance == undefined) return;
        addPerformance(performance, () => {
            navigate("/performances");
        });
    }} title="Copy Performance" onLoad={() => {
        setLoader(true);
        const idcast = Number(id);
        loadOnePerformance(idcast, (p: Performance) => {
            setPerformance(p);
            setTimeout(() => {
                setLoader(false);
            }, 200);
        })
    }} onChange={(key: string, value: any) => {
        if(performance != undefined) {
            var json = JSON.parse(JSON.stringify(performance));
            json[key] = value;
            setPerformance(Object.assign(new Performance(), json));
        }
    }} defaultValue={performance}/>
}

export default CopyPerformance;