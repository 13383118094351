import React, { ReactNode } from "react";

import "./style/iconButton.css";

interface IconButtonProps {
    disabled?: boolean,
    nohover?: boolean,
    onClick: Function,
    children: ReactNode
}

export default function IconButton(props: IconButtonProps) {
    return <div className={"icon-button" + (props.disabled ? " disabled" : "") + (props.nohover ? " nohover" : "")} onClick={!props.disabled ? () => {props.onClick()} : () => {}}>
        {props.children}
    </div>
}