import React, { useState } from "react";

import "./style/imagePicker.css";

import bkg from "../../assets/img/background.webp";
import EditIcon from "../assets/icons/edit";
import AddIcon from "../assets/icons/add";
import { closeAction, openAction } from "../actions/actionWrapper";
import AddImageAction from "../actions/addImageAction";
import EditImageAction from "../actions/editImageAction";

interface ImagePickerProps {
    img?: any,
    label?: string,
    onChange: Function
}

function ImagePicker(props: ImagePickerProps) {

    return <div className="image-picker text-field">
        {props.label == undefined ? <></> : <p>{props.label}</p>}
        {props.img != undefined ? <div onClick={() => {
            openAction(<EditImageAction img={props.img} onFinish={(file: File) => {
                props.onChange(file);
                closeAction();
            }}/>);
        }} className="image-box edit" style={{
            "backgroundImage": "url(" + props.img + ")"
        }}>
            <EditIcon/>
            <p>Edit picture</p>
        </div> : <div className="image-box add" onClick={() => {
            openAction(<AddImageAction onFinish={(file: File) => {
                props.onChange(file);
                closeAction();
            }}/>);
        }}>
            <AddIcon/>
            <p>Add Picture</p>
        </div>}
    </div>;

}

export default ImagePicker;