import React, { useState, useEffect } from "react";
import Button from "../components/button";
import Checkbox from "../components/checkbox";
import TextField from "../components/textField";

import "./style/login.css";
import { checkSessionId } from "../../content/loginManager";
import { useNavigate } from "react-router-dom";

interface LoginPageProps {
    onLogin: Function,
    img: any,
    msg?: string
}

function LoginPage(props: LoginPageProps) {

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [stayLoggedIn, setStayLoggedIn] = useState(false);

    const [sessionCheck, setSessionCheck] = useState(false);

    function executeLogin() {
        props.onLogin(username, password, stayLoggedIn);
    }

    useEffect(() => {
        if(sessionCheck) return;
        setSessionCheck(true);
        checkSessionId(() => {
            navigate("/performances");
        }, () => {

        });
    })

    return <div id="login">
        <div className="form">
            <div className="content">
                <h1>Welcome back!</h1>
                <TextField placeholder="Enter username" onChange={(v: string) => {setUsername(v)}} label="Username" onEnter={executeLogin}/>
                <TextField placeholder="Enter password" onChange={(v: string) => {setPassword(v)}} label="Password" password onEnter={executeLogin}/>
                <Checkbox clickable label="stay logged in" onChange={setStayLoggedIn}/>
                <p className="message">{props.msg}</p>
                <Button onClick={executeLogin}>Login</Button>
            </div>
        </div>
        <div className="picture" style={{
            "backgroundImage": "url(" + props.img + ")"
        }}/>
    </div>;
}

export default LoginPage;