import { openPopUp } from "../framework/components/popUp";
import { getApiUrl } from "./apiManager";
import { getSessionId } from "./loginManager";

function getImageLink(uuid: string) {
    return getApiUrl() + "image/" + uuid;
}

function uploadImage(file: File, onFinish: Function) {
    if(file.size > 210000000) {
        openPopUp("Dein Bild darf maximal 2MB groß sein");
        return;
    }
    const formData = new FormData();
    formData.append("file", file);
    fetch(getApiUrl() + "image", {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': getSessionId(),
            //TODO: Replace with real ip in apache
            "X-Real-IP": "f"
        },
    })
        .then((response) => response.json())
        .then((r) => {
            if(r.status == 200) {
                onFinish(r.data.id);
            }else {
                openPopUp(r.message);
            }
        })
        .catch((err) => {
            console.log("An error occured while api request: " + err);
            //TODO: Error handle
        });
}

export {getImageLink, uploadImage}