import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkSessionId } from "./loginManager";

export default function SessionChecker() {
    const location = useLocation();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(checked) return;
    setChecked(true);
    checkSessionId(() => {
      if(location.pathname == "login") {
        navigate("/performances");
      }
    }, () => {
      if(location.pathname != "login") {
        navigate("/login");
      }
    });
  })
}