import React, { useEffect, useState } from "react";
import DashboardSubPage from "../../framework/pages/dashboardSubPage";

import bkg from "../../assets/img/background.webp";
import ImagePicker from "../../framework/components/imagePicker";
import TextField from "../../framework/components/textField";
import ImagePickerRightLayout from "../../framework/components/layout/imagePickerRightLayout";
import MultipleTextFieldsLayout from "../../framework/components/layout/multipleTextFieldsLayout";
import { setLoader } from "../../framework/components/loader";
import { getImageLink, uploadImage } from "../../content/imageManager";
import { Quote } from "../../content/quoteManager";

interface SinglePerformanceProps {
    title: string
    onChange: Function,
    onLoad?: Function,
    defaultValue?: Quote,
    continueTitle: string,
    onContinue: Function
}

function SingleQuote(props: SinglePerformanceProps) {

    const quote = props.defaultValue;

    return <DashboardSubPage continueTitle={props.continueTitle} onContinue={props.onContinue} onLoad={props.onLoad} title={props.title} backLink="quotes" img={bkg}>
            <TextField defaultValue={quote == undefined ? "" : quote.publisher} placeholder="Enter publisher" label="Publisher*" onChange={((v: string) => {
                props.onChange("publisher", v);
            })}/>
            <TextField defaultValue={quote == undefined ? "" : quote.location} placeholder="Enter location" label="Location*" onChange={((v: string) => {
                props.onChange("location", v);
            })}/>
            <TextField defaultValue={quote == undefined ? "" : quote.quote} textarea placeholder="Enter quote" label="Quote*" onChange={((v: string) => {
                props.onChange("quote", v);
            })}/>
        
    </DashboardSubPage>
}

export default SingleQuote;