import React, { useEffect, useState } from "react";
import DashboardSubPage from "../../framework/pages/dashboardSubPage";

import bkg from "../../assets/img/background.webp";
import ImagePicker from "../../framework/components/imagePicker";
import TextField from "../../framework/components/textField";
import ImagePickerRightLayout from "../../framework/components/layout/imagePickerRightLayout";
import MultipleTextFieldsLayout from "../../framework/components/layout/multipleTextFieldsLayout";
import { setLoader } from "../../framework/components/loader";
import { useParams } from "react-router-dom";
import { loadOnePerformance, Performance, updatePerformance } from "../../content/performanceManager";
import { getImageLink, uploadImage } from "../../content/imageManager";

interface SinglePerformanceProps {
    title: string
    onChange: Function,
    onLoad?: Function,
    defaultValue?: Performance,
    continueTitle: string,
    onContinue: Function
}

function SinglePerformance(props: SinglePerformanceProps) {


    const [firstExecute, setFirstExecute] = useState(false);

    const performance = props.defaultValue;

    const [imageLink, setImageLink] = useState<string>();

    useEffect(() => {
        if(!firstExecute && performance != undefined && performance.image != undefined && imageLink != performance.image) {
            setImageLink(getImageLink(performance.image));
            setFirstExecute(true);
        }
    })

    return <DashboardSubPage continueTitle={props.continueTitle} onContinue={props.onContinue} onLoad={props.onLoad} title={props.title} backLink="performances" img={bkg}>
        <ImagePickerRightLayout imagePicker={
            <><ImagePicker onChange={(file: File) => {
                setLoader(true);
                if(file == undefined) {
                    setImageLink(undefined);
                    setLoader(false);
                    props.onChange("image", null);
                    return;

                }
                uploadImage(file, (imageid: string) => {
                    setImageLink(getImageLink(imageid));
                    setLoader(false);
                    props.onChange("image", imageid);
                });
            }} img={imageLink} label="Picture"/>
            </>
            }>
                <>
                <MultipleTextFieldsLayout>
                    <TextField defaultValue={performance == undefined ? "" : performance!.category} placeholder="Enter Category" label="Category*" onChange={(v: string) => {
                        props.onChange("category", v);
                    }}/>
                    <TextField defaultValue={performance == undefined ? "" : String(performance!.date)} placeholder="Enter Date" label="Date*" date onChange={(v: string) => {
                        props.onChange("date", v);
                    }}/>
                </MultipleTextFieldsLayout>
                <MultipleTextFieldsLayout>
                    <TextField defaultValue={performance == undefined ? "" : performance!.location} placeholder="Enter Location" label="Location*" onChange={(v: string) => {
                        props.onChange("location", v);
                    }}/>
                    <TextField defaultValue={performance == undefined ? "" : performance!.hall} placeholder="Enter Hall" label="Hall" onChange={(v: string) => {
                        props.onChange("hall", v);
                    }}/>
                </MultipleTextFieldsLayout>
                </>
            </ImagePickerRightLayout>
            <MultipleTextFieldsLayout>
                <TextField defaultValue={performance == undefined ? "" : performance!.feature} placeholder="Enter Feature" label="Feature" onChange={(v: string) => {
                    props.onChange("feature", v);
                }}/>
                <TextField defaultValue={performance == undefined ? "" : performance!.featureShort} placeholder="Enter short version" label="Short version feature" onChange={(v: string) => {
                    props.onChange("featureShort", v);
                }}/>
            </MultipleTextFieldsLayout>
            <TextField defaultValue={performance == undefined ? "" : performance!.link} placeholder="Enter Link" label="Link" onChange={(v: string) => {
                props.onChange("link", v);
            }}/>
            <TextField defaultValue={performance == undefined ? "" : performance!.info} placeholder="Enter Info" label="Info" onChange={(v: string) => {
                props.onChange("info", v);
            }}/>
            <TextField defaultValue={performance == undefined ? "" : performance!.composer} placeholder="Enter Composer" label="Composer*" onChange={(v: string) => {
                props.onChange("composer", v);
            }}/>
        
    </DashboardSubPage>
}

export default SinglePerformance;