import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import bkg from "../assets/img/background.webp";
import { login } from "../content/loginManager";

import LoginPage from "../framework/pages/loginPage";

export default function Login() {

    const navigate = useNavigate();

    const [msg, setMsg] = useState<string>();

    return <LoginPage msg={msg} img={bkg} onLogin={(username: string, password: string, stayLoggedIn: boolean) => {
        login(username, password, stayLoggedIn, () => {
            navigate("/performances");
        }, (m: string) => {
            setMsg(m);
        });
    }}></LoginPage>;
}