import React, { useEffect, useState } from "react";

import DashboardPage from "../../framework/pages/dashboard";
import bkg from "../../assets/img/background.webp";
import SearchButtonHeader from "../../framework/components/headers/searchButtonHeader";
import AddIcon from "../../framework/assets/icons/add";
import SearchCreateButtonHeader from "../../framework/components/headers/searchCreateButtonHeader";
import ListView from "../../framework/components/views/listView";
import ListElement from "../../framework/components/listElement";
import { deletePerformance, getPerformances, loadPastPerformances, loadPerformances, Performance } from "../../content/performanceManager";
import { setLoader } from "../../framework/components/loader";
import { formatDate } from "../../framework/utils/formatUtils";
import { useNavigate, useParams } from "react-router-dom";
import { getImageLink } from "../../content/imageManager";
import { closeAction, openAction } from "../../framework/actions/actionWrapper";
import OptionAction, { OptionActionOption } from "../../framework/actions/optionAction";
import SearchHeader from "../../framework/components/headers/searchHeader";
import { updateStatic } from "../../content/staticManager";

export default function SelectPastPerformance() {

    const {number} = useParams();

    const navigate = useNavigate();

    const [performances, setPerformances] = useState<Performance[]>([]);
    const [search, setSearch] = useState<string>();

    function loadData() {
        setLoader(true);
        loadPastPerformances((p: any) => {
            setPerformances(p);
            setTimeout(() => {
                setLoader(false);
            }, 200);
        });
    }

    return <DashboardPage onLoad={loadData} img={bkg}>
        <h1>Click to select performance</h1>
        <SearchHeader onSearch={setSearch}/>
        <ListView>
            {performances.map(e => {
                if(search != undefined && !e.search(search)) return;
                return <ListElement onClick={() => {
                    updateStatic("past_performance_" + number, e.id + "", () => {
                        navigate("/past-performances");
                    })
                }} nohover date={formatDate(e.date == null ? new Date() : e.date)} title={e.category + " " + e.location} subtitle={e.feature == null ? e.composer == null ? "" : e.composer : e.feature} img={e.image == undefined ? undefined : getImageLink(e.image)}/>;
            })}
        </ListView>
    </DashboardPage>
}