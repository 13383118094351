import logo from './logo.svg';
import './App.css';
import Login from './pages/login.tsx';

import "./framework/general.css";
import DashboardPage from './framework/pages/dashboard.tsx';
import Performances from './pages/performances.tsx';
import EditPerformance from './pages/performances/editPerformance';
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { checkSessionId } from './content/loginManager';
import SessionChecker from './content/sessionChecker';
import AddPerformance from './pages/performances/addPerformance';
import CopyPerformance from './pages/performances/copyPerformance';
import Quotes from './pages/quotes';
import AddQuote from './pages/quotes/addQuote';
import EditQuote from './pages/quotes/editQuote';
import CopyQuote from './pages/quotes/copyQuote';
import Biography from './pages/biography';
import BiographyPage from './pages/biography';
import VideoPage from './pages/videos';
import EditBiographySection from './pages/biography/editBiographySection';
import EditVideoPage from './pages/videos/editVideo';
import ImageGalleryPage from './pages/gallery';
import PastPerformancesPage from './pages/pastPerformances';
import SelectPastPerformance from './pages/performances/selectPastPerformance';

function App() {

  return (
    <BrowserRouter>
    <Routes>
      <Route path="*" element={<Navigate to="login"/>}/>
      <Route path="login" element={<Login/>}/>
      <Route path="past-performances" element={<PastPerformancesPage/>}/>
      <Route path="past-performances/select/:number" element={<SelectPastPerformance/>}/>
      <Route path="performances" element={<Performances/>}/>
      <Route path="performances/edit/:id" element={<EditPerformance/>}/>
      <Route path="performances/copy/:id" element={<CopyPerformance/>}/>
      <Route path="performances/add" element={<AddPerformance/>}/>
      <Route path="quotes" element={<Quotes/>}/>
      <Route path="quotes/add" element={<AddQuote/>}/>
      <Route path="quotes/edit/:id" element={<EditQuote/>}/>
      <Route path="quotes/copy/:id" element={<CopyQuote/>}/>
      <Route path="biography" element={<BiographyPage/>}/>
      <Route path="biography/:id" element={<EditBiographySection/>}/>
      <Route path="videos" element={<VideoPage/>}/>
      <Route path="videos/:id" element={<EditVideoPage/>}/>
      <Route path="gallery" element={<ImageGalleryPage/>}/>
    </Routes>
    <SessionChecker/>
    </BrowserRouter>
  );
}

export default App;
