import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addQuote, loadOneQuote, Quote, updateQuote } from "../../content/quoteManager";
import { setLoader } from "../../framework/components/loader";
import SingleQuote from "./singleQuote";

function EditQuote() {

    const {id} = useParams();
    
    const navigate = useNavigate();

    const [quote, setQuote] = useState<Quote>();

    return <SingleQuote defaultValue={quote} continueTitle="update" onLoad={() => {
        setLoader(true);
        const idcast = Number(id);
        loadOneQuote(idcast, (q: Quote) => {
            setQuote(q);
            setTimeout(() => {
                setLoader(false);
            }, 200);
        })
    }} onContinue={() => {
        if(quote == undefined) return;
        updateQuote(quote, () => {
            navigate("/quotes");
        })
    }} title="Edit Quote" onChange={(key: string, value: string) => {
        var json = JSON.parse(JSON.stringify(quote));
        json[key] = value;
        setQuote(Object.assign(new Quote(), json));
    }}/>;

}

export default EditQuote;