import React, { useState } from "react";
import SmallView from "../framework/components/views/smallView";
import DashboardPage from "../framework/pages/dashboard";

import bkg from "../assets/img/background.webp";
import TextField from "../framework/components/textField";
import GridView from "../framework/components/views/gridView";
import SimpleBox from "../framework/components/simpleBox";
import { useNavigate } from "react-router-dom";

function VideoPage() {
    
    const navigate = useNavigate();


    return <DashboardPage img={bkg}>
        <h1>Home - Videos</h1>
        <GridView label="Videos" elementCount={5}>
            <SimpleBox title="Video 1" onClick={() => {
                navigate("/videos/1");
            }}/>
            <SimpleBox title="Video 2" onClick={() => {
                navigate("/videos/2");
                
            }}/>
        </GridView>

    </DashboardPage>

}

export default VideoPage;