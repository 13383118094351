import React from "react";

import "./style/loader.css";

interface LoaderProps {
    disabled: boolean
}

export default function Loader(props: LoaderProps) {
    return <div className={"loader-wrapper" + (props.disabled ? " disabled" : "")}>
        <span className="loader"></span>
    </div>;
}

function setLoader(value: boolean) {
    const loaderEvent = new CustomEvent("setLoader", {
        detail: {
            state: value
        },
        bubbles: true,
        cancelable: true,
        composed: false,
      });
    window.dispatchEvent(loaderEvent);
    console.log(value);
}

export {setLoader};