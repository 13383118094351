import React, { ReactNode, useEffect, useState } from "react";

import "./style/dashboard.css"
import Navigation from "../components/navigation";
import Loader from "../components/loader";
import PopUp from "../components/popUp";
import ActionWrapper from "../actions/actionWrapper";


interface DashboardProps {
    img: any,
    children: ReactNode,
    className?: string,
    onLoad?: Function
}

function DashboardPage(props: DashboardProps) {

    const [firstExecute, setFirstExecute] = useState(false);

    const [loader, setLoader] = useState(false);
    const [loaderDisabled, setLoaderDisabled] = useState(false);

    const handleLoaderEvent = ((event: CustomEvent) => {
        const value = event.detail.state;
        if(value == false) {
            setLoaderDisabled(true);
            setTimeout(() => {
                setLoader(value);
            }, 400);
        }else {
            setLoaderDisabled(false);
            setLoader(true);
        }
    }) as EventListener;

    useEffect(() => {
        window.addEventListener("setLoader", handleLoaderEvent);
        if(!firstExecute) {
            setFirstExecute(true);
            if(props.onLoad) {
                props.onLoad();
            }

        }
        return () => {
            window.removeEventListener("setLoader", handleLoaderEvent);
        }
    })

    return <div className={"dashboard" + (props.className ? " " + props.className : "")}>
        <Navigation/>
        <PopUp/>
        <ActionWrapper/>
        <div className="content" style={{
            "backgroundImage": "url(" + props.img + ")"
        }}>
            <div className="main-box">
                {loader ? <Loader disabled={loaderDisabled}/> : <></>}
                {props.children}
                
            </div>
        </div>
    </div>
}

export default DashboardPage;