import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardSubPageSaveButton from "../../framework/pages/dashboardSubPageSaveButton";

import bkg from "../../assets/img/background.webp";
import TextField from "../../framework/components/textField";
import SmallView from "../../framework/components/views/smallView";
import { Biography, loadBiography, updateBiography } from "../../content/biographyManager";
import { setLoader } from "../../framework/components/loader";

function EditBiographySection() {

    const navigate = useNavigate();

    const {id} = useParams();

    const [bio, setBio] = useState<Biography>();

    const [title, setTitle] = useState<string>();
    const [text, setText] = useState<string>();

    function loadData() {
        loadBiography(Number(id), (bio: Biography) => {
            setTitle(bio.title);
            setText(bio.text);
            setBio(bio);
        });
    }

    return <DashboardSubPageSaveButton disabledMessage="No changes made" disabled={bio == undefined || (bio.text == text && bio.title == title)} onLoad={loadData} img={bkg} backLink="biography" title={"Section " + id} onSave={() => {
        setLoader(true);
        const temp = bio ? bio : new Biography();
        temp.text = text;
        temp.title = title;
        updateBiography(temp, () => {
            setLoader(false);
            navigate("/biography");
        })
    }}>
        <SmallView>
            <TextField defaultValue={title} placeholder="Enter title" label="Title" onChange={(v: string) => {
                setTitle(v);
            }}/>
            <TextField defaultValue={text} textarea placeholder="Enter text" label="Text" onChange={(v: string) => {
                setText(v);
            }}/>
        </SmallView>
    </DashboardSubPageSaveButton>
}

export default EditBiographySection;