import React, { ReactNode } from "react";
import ImagePicker from "../imagePicker";

import "./layouts.css";

interface ImageRightLayoutProps {
    imagePicker: ReactNode,
    children: ReactNode
}

function ImagePickerRightLayout(props: ImageRightLayoutProps) {
    return <div className="layout picker-right">
        <div className="children">
            {props.children}
        </div>
        {props.imagePicker}
    </div>
}

export default ImagePickerRightLayout;