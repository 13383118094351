import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardSubPageSaveButton from "../../framework/pages/dashboardSubPageSaveButton";
import bkg from "../../assets/img/background.webp";
import TextField from "../../framework/components/textField";
import { loadVideoObject, updateVideo, VideoObject } from "../../content/videosManager";
import { setLoader } from "../../framework/components/loader";

function EditVideoPage() {

    const navigate = useNavigate();

    const {id} = useParams();

    const [video, setVideo] = useState<VideoObject>();

    const [name, setName] = useState<string>();
    const [link, setLink] = useState<string>();
    const [desc, setDesc] = useState<string>();

    function loadData() {
        loadVideoObject(Number(id), (d: any) => {
            setVideo(d);
            setName(d.name);
            setLink(d.link);
            setDesc(d.description);
            console.log(d.name);
        })
    }

    return <DashboardSubPageSaveButton disabledMessage="No changes made" disabled={video != undefined && (video.name == name && video.link == link && video.description == desc)} onLoad={loadData} backLink="videos" title={"Video " + id} img={bkg} onSave={() => {
        setLoader(true);
        const temp = video ? video : new VideoObject();
        temp.name = name;
        temp.description = desc;
        temp.link = link;
        updateVideo(temp, () => {
            setLoader(false);
            navigate("/videos");
        })
    }}>
        <TextField defaultValue={name} placeholder="Enter name" label="name" onChange={setName}/>
        <TextField defaultValue={link} placeholder="Enter link" label="Link" onChange={setLink}/>
        <TextField defaultValue={desc} textarea autogrow placeholder="Enter description" label="Description" onChange={setDesc}/>
    </DashboardSubPageSaveButton>;

}

export default EditVideoPage;