import { openPopUp } from "../framework/components/popUp";
import { formatDate } from "../framework/utils/formatUtils";
import { sendNoAuthRequest, sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

class Performance {
    id: number = -1;
    category?: string;
    location?: string;
    hall?: string;
    link?: string;
    feature?: string;
    featureShort?: string;
    composer?: string;
    info?: string;
    image?: string;
    date: Date | null = null;
    /*constructor(id: number, category: string, location: string, hall: string, link: string, feature: string, featureShort: string, composer: string, info: string, image: string, date: Date) {
        this.category= category;
        this.location = location;
        this.hall = hall;
        this.link = link;
        this.feature= feature;
        this.featureShort = featureShort;
        this.composer = composer;
        this.info = info;
        this.image = image;
        this.date = date;
        this.id = id;
    }*/
    search(text: string) {
        var split = text.split(" ");
        for(var i = 0; i < split.length; i++) {
            const current = split[i].toLowerCase();
            if(this.category?.toLowerCase().includes(current) || this.location?.toLowerCase().includes(current) || this.hall?.toLowerCase().includes(current) || this.feature?.toLowerCase().includes(current) || this.composer?.toLowerCase().includes(current) || formatDate(this.date == null ? new Date() : this.date).toLowerCase().includes(current) ||  this.info?.toLowerCase().includes(current)) return true;
        }
        return false;
   }
}

var performances: Performance[] = [];

function getPerformances() {
    return performances;
}

function loadPerformances(onFinish: Function) {
    sendNoAuthRequest("performance", "GET", {}, (r: any) => {
        if(r.status == 200) {
            performances = [];
            for(var i = 0; i < r.data.performances.length; i++) {
                const current = Object.assign(new Performance(), r.data.performances[i]);
                performances.push(current);
            }
            onFinish(performances);
        }else {
            openPopUp(r.message);
        }
    });
}

function loadOnePerformance(id: number, onFinish: Function) {
    sendNoAuthRequest("performance/" + id, "GET", {}, (r: any) => {
        onFinish(Object.assign(new Performance(), r.data.performance));
    });
}

function checkPerformance(performance: Performance) {
    if(performance.category != undefined && performance.category != null && performance.location != undefined && performance.composer != null && performance.composer != undefined && performance.location != null && performance.date != undefined && performance.date != null) {
        return true;
    }else {
        openPopUp("Please fill in all required fields");
        return false;
    }
}

function updatePerformance(performance: Performance, onFinish: Function) {
    if(!checkPerformance(performance)) return;
    sendRequest("performance/" + performance.id, "POST", JSON.parse(JSON.stringify(performance)), (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            console.log(r);
        }
    }, getSessionId());
}

function addPerformance(performance: Performance, onFinish: Function) {
    if(!checkPerformance(performance)) return;
    sendRequest("performance", "POST", JSON.parse(JSON.stringify(performance)), (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            console.log(r);
        }
    }, getSessionId());
}

function deletePerformance(id: number, onFinish: Function) {
    sendRequest("performance/" + id, "DELETE", {}, (r: any) => {
        if(r.status == 200) {
            onFinish();
        }else {
            console.log(r);
        }
    }, getSessionId());
}

function loadUpcomingPerformances(onFinish: Function) {
    sendNoAuthRequest("performance/upcoming", "GET", {}, (r: any) => {
        if(r.status == 200) {
            performances = [];
            for(var i = 0; i < r.data.performances.length; i++) {
                const current = Object.assign(new Performance(), r.data.performances[i]);
                performances.push(current);
            }
            onFinish(performances);
        }else {
            openPopUp(r.message);
        }
    });
}

function loadPastPerformances(onFinish: Function) {
    sendNoAuthRequest("performance/past", "GET", {}, (r: any) => {
        if(r.status == 200) {
            performances = [];
            for(var i = 0; i < r.data.performances.length; i++) {
                const current = Object.assign(new Performance(), r.data.performances[i]);
                performances.push(current);
            }
            onFinish(performances);
        }else {
            openPopUp(r.message);
        }
    });
}

export {Performance, getPerformances, loadPerformances, loadOnePerformance, updatePerformance, addPerformance, deletePerformance, loadUpcomingPerformances, loadPastPerformances}