import React, { ReactNode } from "react";

import "./style/listView.css";

interface ListViewProps {
    children: ReactNode
}

function ListView(props: ListViewProps) {
    return <div className="list-view">
        {props.children}
    </div>;
}

export default ListView;